import styled from 'styled-components'
import { FColG16, FRow, FRowG16 } from '../../atoms/Layout'
import { columnGap } from '../../utils'
import { Link as _Link } from 'gatsby-plugin-react-i18next'

export const Link = styled(_Link)`
    display: flex;
`
export const NavButton = styled(Link)`
    font-weight: 700;
    font-size: 1rem;
    position: relative;

    ::before {
        box-sizing: border-box;
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: scale(0);
        height: 0.5px;
        background-color: rgb(28, 33, 31);
        transition: transform 0.8s cubic-bezier(0.22, 1, 0.36, 1) 0s;
        transform-origin: right center;
    }

    :hover {
        ::before {
            transform: scale(1);
            transform-origin: left center;
        }
    }
`
export const NavButtonMobile = styled(Link)`
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    :hover {
        opacity: 0.7;
    }
`
export const Wrapper = styled(FRow)`
    justify-content: center;
    padding: 0 var(--content-padding);
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    background-color: white;
    z-index: 100;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
`
export const FullBackground = styled(FColG16)`
    position: absolute;
    bottom: calc(100% - 100vh);
    left: 0;
    right: 0;
    top: 100%;
    background-color: white;
    padding: 0 var(--content-padding) 32px var(--content-padding);
    overflow: auto;
`
export const InnerWrapper = styled(FRowG16)`
    max-width: var(--content-width);
    padding: 15px 0;
    align-items: center;
    width: 100%;
`
export const NavWrapper = styled(FRow)`
    ${columnGap(32)}
`
export const WeirdText = styled(FRowG16)`
    justify-content: space-between;
    margin-bottom: 32px;
    > * {
        text-transform: uppercase;
        font-size: 0.875rem;
        color: #aab1bd;
        font-weight: 500;
    }
`
