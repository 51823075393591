import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import Content from '../../atoms/Content'
import { FColG16, FExpand } from '../../atoms/Layout'
import Logo from '../../atoms/Icons/Logo'
import SocialNetworks from '../../molecules/SocialNetworks'
import * as S from './styled'
import { ESTIMATE, GALLERY, HOW_IT_WORKS, REVIEWS, SCHEDULE } from '../../urls'

export type FooterProps = {}

export const Footer: React.FC<FooterProps> = React.memo(({}) => {
    const { t } = useTranslation(['common'])
    return (
        <S.SBg color={'white'} useHPadding={true}>
            <Content variant={'transparent'} useMaxWidth={true} expanded={true}>
                <S.ContentLayout>
                    <FExpand>
                        <S.LogoCol>
                            <Logo />
                            <div>{t('footer.description')}</div>
                            <div>{t('footer.license')}</div>
                        </S.LogoCol>
                    </FExpand>
                    <FColG16>
                        <S.Title>{t('footer.ourServices')}</S.Title>
                        <div>
                            <Link to={HOW_IT_WORKS}>{t('howItWorks')}</Link>
                        </div>
                        <div>
                            <Link to={REVIEWS}>{t('reviews')}</Link>
                        </div>
                        <div>
                            <Link to={GALLERY}>{t('gallery')}</Link>
                        </div>
                        <div>
                            <Link to={SCHEDULE}>{t('schedule')}</Link>
                        </div>
                        <div>
                            <Link to={ESTIMATE}>{t('getAnEstimate')}</Link>
                        </div>
                    </FColG16>
                    <FColG16>
                        <S.Title>{t('contact')}</S.Title>
                        <div>{'conamerservices@gmail.com'}</div>
                        <div>{'+58 (424) 142-6532'}</div>
                        <div>{'Caracas, Venezuela'}</div>
                        <div />
                        <S.Title>{t('footer.followUs')}</S.Title>
                        <SocialNetworks />
                    </FColG16>
                </S.ContentLayout>
                <S.SCopyright />
            </Content>
        </S.SBg>
    )
})

Footer.displayName = 'Footer'

export default Footer
