import React from 'react'
import en from '../../images/en.svg'
import es from '../../images/es.svg'
import { useI18next } from 'gatsby-plugin-react-i18next'
import * as S from './styled'

const langFlags = {
    en,
    es,
}

export type LangButtonProps = {
    lng: keyof typeof langFlags
}

export const LangButton: React.FC<LangButtonProps> = React.memo(({ lng }) => {
    const { originalPath, language } = useI18next()
    const Flag = langFlags[lng]

    const isSelected = language === lng
    React.useLayoutEffect(() => {
        if (isSelected) {
            document.cookie = `firebase-language-override=${lng};path=/`
        }
    }, [isSelected])

    return (
        <S.Circle selected={isSelected}>
            <S.SIconButton>
                <S.SLink to={originalPath} language={lng}>
                    <Flag />
                </S.SLink>
            </S.SIconButton>
        </S.Circle>
    )
})

LangButton.displayName = 'LangButton'

export default LangButton
