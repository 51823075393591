import React from 'react'
import Facebook from '../../atoms/Icons/Facebook'
import Pinterest from '../../atoms/Icons/Pinterest'
import Linkedin from '../../atoms/Icons/Linkedin'
import Twitter from '../../atoms/Icons/Twitter'
import Instagram from '../../atoms/Icons/Instagram'
import { FRowG8 } from '../../atoms/Layout'

export type SocialNetworksProps = {}

export const SocialNetworks: React.FC<SocialNetworksProps> = React.memo(({}) => {
    return (
        <FRowG8>
            {/*<Facebook />*/}
            {/*<Pinterest />*/}
            {/*<Linkedin />*/}
            {/*<Twitter />*/}
            <Instagram />
        </FRowG8>
    )
})

SocialNetworks.displayName = 'SocialNetworks'

export default SocialNetworks
