import React from 'react'
import { Wrapper } from './styled'

export type CopyrightProps = {} & React.HTMLAttributes<HTMLDivElement>

export const Copyright: React.FC<CopyrightProps> = React.memo((rest) => {
    return <Wrapper {...rest}>© Conamer Tech {new Date().getFullYear()}</Wrapper>
})

Copyright.displayName = 'Copyright'

export default Copyright
