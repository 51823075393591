import React from 'react'
import { ButtonBlack, ButtonWArrow, ButtonWhite } from '../../atoms/Buttons/Button'
import ThinArrow from '../../atoms/Icons/ThinArrow'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { ESTIMATE } from '../../urls'

export type EstimateButtonProps = {
    size?: React.ComponentProps<typeof ButtonWArrow>['size']
    theme?: 'dark' | 'light'
} & Omit<React.ComponentProps<typeof Link>, 'to'>

export const EstimateButton = React.memo<EstimateButtonProps>(
    ({ size = 'large', theme = 'light', ...props }) => {
        const { t } = useTranslation('common')
        return (
            <Link {...props} to={ESTIMATE}>
                <ButtonWArrow
                    as={{ light: ButtonBlack, dark: ButtonWhite }[theme]}
                    size={size}
                >
                    {t('common:getAnEstimate')}
                    <ThinArrow />
                </ButtonWArrow>
            </Link>
        )
    },
)

EstimateButton.displayName = 'EstimateButton'

export default EstimateButton
