import styled, { css, keyframes } from 'styled-components'
import { FCol } from '../Layout'
import React from 'react'
import { FadeIn } from '../Animations'

export const colors = {
    blue: '#E0EBED',
    flesh: '#FEEBDF',
    white: '#FFFFFF',
}

// From black png images to target color
export const colorFilters = {
    blue: 'invert(96%) sepia(2%) saturate(2055%) hue-rotate(174deg) brightness(102%) contrast(86%)',
    flesh: 'invert(88%) sepia(10%) saturate(478%) hue-rotate(330deg) brightness(105%) contrast(99%)',
    white: 'invert(99%) sepia(77%) saturate(2%) hue-rotate(200deg) brightness(120%) contrast(100%)',
}

export type BgProps = {
    color: keyof typeof colors
    amount?: number
    deg?: number
    useHPadding?: boolean
    animation?: any
}

export const BgH = styled(FCol)<Pick<BgProps, 'useHPadding'>>`
    position: relative;
    width: 100%;
    align-items: center;
    padding-left: ${(props) =>
        props.useHPadding ? 'var(--content-padding)' : undefined};
    padding-right: ${(props) =>
        props.useHPadding ? 'var(--content-padding)' : undefined};
`

const fsAnim = keyframes`
    0% {
        width: 0;
    }
    100% {
        width: var(--amount);
    }
`

const fsAnimLr = keyframes`
    0% {
        left: calc(100%);
        width: 0;
    }
    100% {
        left: calc(100% - var(--amount));
        width: var(--amount);
    }
`

export const bgFadeGrowLR = css`
    animation: ${fsAnim} 1000ms ease-in-out forwards, ${FadeIn};
`
export const bgFadeGrowRL = css`
    animation: ${fsAnimLr} 1000ms ease-in-out forwards, ${FadeIn};
`
export const bgVertical = css`
    bottom: unset;
    right: 0;
    height: var(--amount);
`

export const bgHorizontal = css`
    width: var(--amount);
`

const BgHorizontal = styled.div`
    ${bgHorizontal}
`

export const NormalBg = styled.div`
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    position: absolute;
`

const BgWrapper = styled(BgH)`
    position: relative;
    display: block;
`

export const Bg: React.FC<React.ComponentProps<typeof BgH> & BgProps> = ({
    color,
    amount = 1,
    children,
    animation = BgHorizontal,
    ...props
}) => {
    return (
        <BgWrapper>
            <NormalBg
                as={animation}
                style={{
                    '--amount': amount * 100 + '%',
                    backgroundColor: colors[color] ?? colors.white,
                }}
            />
            <BgH {...props} style={{ zIndex: 1, ...props.style }}>
                {children}
            </BgH>
        </BgWrapper>
    )
}
