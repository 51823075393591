import styled from 'styled-components'
import { FColG16, FRowG128 } from '../../atoms/Layout'
import { sBreakpoints } from '../../constants'
import { columnGap, rowGap } from '../../utils'
import { Bg } from '../../atoms/Bg'
import Copyright from '../../atoms/Copyright'

export const Title = styled.div`
    font-weight: bold;
`

export const ContentLayout = styled(FRowG128)`
    ${sBreakpoints.mobile} {
        flex-direction: column;
        ${columnGap(0)}
        ${rowGap(16)}
        margin-bottom: 32px;
    }
`

export const SBg = styled(Bg)`
    align-items: center;
    overflow: hidden;
    padding-bottom: 32px;
`

export const LogoCol = styled(FColG16)`
    ${sBreakpoints.notMobile} {
        max-width: 350px;
    }
`

export const SCopyright = styled(Copyright)`
    justify-content: center;
`
