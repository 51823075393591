import styled from 'styled-components'
import { FRow } from '../Layout'
import { mainColor } from '../../gatsby-theme-material-ui-top-layout/theme'
import { lighten } from '@material-ui/core'
import { Link } from 'gatsby-plugin-react-i18next'
import { IconButton } from 'gatsby-theme-material-ui'

export const SIconButton = styled(IconButton)`
    padding: 0;
`

export const Circle = styled(FRow)<{ selected?: boolean }>`
    border-radius: 50%;
    border-width: 2px;
    border-color: ${({ selected }) => {
        return selected ? mainColor : 'rgba(255, 255, 255, 0)'
    }};
    border-style: solid;
    padding: 2px;
    transition: all 300ms ease-in-out;
    overflow: hidden;
    font-size: 26px;
    width: 1em;
    height: 1em;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    pointer-events: ${(p) => (p.selected ? 'none' : 'all')};
    :hover {
        border-color: ${lighten(mainColor, 0.1)};
    }
`

export const SLink = styled(Link)`
    display: flex;
`
