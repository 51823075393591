import React from 'react'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { FExpand } from '../../atoms/Layout'
import { ButtonPalid, ButtonWArrow } from '../../atoms/Buttons/Button'
import ThinArrow from '../../atoms/Icons/ThinArrow'
import Logo from '../../images/logo.svg'
import LangButton from '../../atoms/LangButton'
import { useBreakpoints, useDocumentScroll } from '../../hooks'
import { IconButton } from 'gatsby-theme-material-ui'
import { Fade } from '@material-ui/core'
import Hamburger from '../../atoms/Icons/Hamburger'
import SocialNetworks from '../SocialNetworks'
import Copyright from '../../atoms/Copyright'
import { useRenderBreakpoints } from '../../utils/hooks/render'
import {
    FullBackground,
    InnerWrapper,
    Link,
    NavButton,
    NavButtonMobile,
    NavWrapper,
    WeirdText,
    Wrapper,
} from './styled'
import { ESTIMATE, GALLERY, HOW_IT_WORKS, REVIEWS, SCHEDULE } from '../../urls'

export type HeaderProps = {}

export const Header: React.FC<HeaderProps> = React.memo(({}) => {
    const { t } = useTranslation('common')
    const { languages } = useI18next()
    const scroll = useDocumentScroll()
    const [displayMenu, setDisplayMenu] = React.useState(false)
    const { onLaptop } = useRenderBreakpoints()
    const { laptop } = useBreakpoints()

    React.useLayoutEffect(() => {
        if (displayMenu) scroll.hide()
        else scroll.restore()
    }, [displayMenu])

    React.useLayoutEffect(() => {
        if (!laptop) setDisplayMenu(false)
    }, [laptop])

    return (
        <Wrapper>
            <InnerWrapper>
                <Link to={'/'}>
                    <Logo style={{ maxWidth: 181 }} />
                </Link>
                {onLaptop(
                    () => (
                        <React.Fragment>
                            <div />
                            <div />
                            <NavWrapper>
                                <NavButton to={HOW_IT_WORKS}>{t('howItWorks')}</NavButton>
                                <NavButton to={REVIEWS}>{t('reviews')}</NavButton>
                                <NavButton to={GALLERY}>{t('gallery')}</NavButton>
                                <NavButton to={SCHEDULE}>{t('schedule')}</NavButton>
                            </NavWrapper>
                        </React.Fragment>
                    ),
                    {
                        mode: 'hide',
                    },
                )}
                <FExpand />
                {languages.map((lng) => {
                    return <LangButton key={lng} lng={lng as 'es' | 'en'} />
                })}
                {onLaptop(() => (
                    <IconButton
                        onClick={() => {
                            setDisplayMenu((p) => !p)
                        }}
                    >
                        <Hamburger asX={displayMenu} />
                    </IconButton>
                ))}
                {onLaptop(
                    () => (
                        <Link to={ESTIMATE}>
                            <ButtonWArrow as={ButtonPalid}>
                                {t('getAnEstimate')}
                                <ThinArrow />
                            </ButtonWArrow>
                        </Link>
                    ),
                    {
                        mode: 'hide',
                    },
                )}
            </InnerWrapper>
            <Fade in={displayMenu} mountOnEnter={true} unmountOnExit={true}>
                <FullBackground>
                    <WeirdText>
                        <div>Construcciones</div>
                        <div>Remodelaciones</div>
                    </WeirdText>
                    <NavButtonMobile to={HOW_IT_WORKS}>{t('howItWorks')}</NavButtonMobile>
                    <NavButtonMobile to={REVIEWS}>{t('reviews')}</NavButtonMobile>
                    <NavButtonMobile to={GALLERY}>{t('gallery')}</NavButtonMobile>
                    <NavButtonMobile to={ESTIMATE}>{t('getAnEstimate')}</NavButtonMobile>
                    <NavButtonMobile to={SCHEDULE}>{t('schedule')}</NavButtonMobile>
                    <FExpand />
                    <SocialNetworks />
                    <Copyright />
                </FullBackground>
            </Fade>
        </Wrapper>
    )
})

Header.displayName = 'Header'

export default Header
