import { css, Keyframes, keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const translateRL = keyframes`
    from {
        position: relative;
        left: 25%;
    }
    
    to {
        position: relative;
        left: 0;
    }
`
const translateLR = keyframes`
    from {
        position: relative;
        left: -25%;
    }
    
    to {
        position: relative;
        left: 0;
    }
`

const create = ({
    name,
    duration,
    type = 'ease-in-out',
}: {
    name: string | Keyframes
    duration: number
    type?: string
}) => {
    return css`
        ${name} ${duration}ms ${type}
    `
}

export const FadeIn = create({ name: fadeIn, duration: 1500 })
export const TranslateRL = create({ name: translateRL, duration: 1000 })
export const TranslateLR = create({ name: translateLR, duration: 1000 })
